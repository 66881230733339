<template>
  <div id="page-login" class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
    <div class="m-4 md:w-2/5 sm:m-0 sm:w-2/3 vx-col w-3/4 lg:w-1/3 ">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col w-full d-theme-dark-bg p-5">
              <div class="vx-card__title mb-4">
                <h1 class="mb-4">Setare parolă</h1>
              </div>

              <div>
                <vs-input
                  v-model="passwordResetData.password"
                  class="w-full mt-6"
                  label-placeholder="Password"
                  name="password"
                  placeholder="Password"
                  type="password"/>
                <error-messages :errors="errors.password"/>

                <vs-input
                  v-model="passwordResetData.password_confirmation"
                  class="w-full mb-6 mt-6"
                  label-placeholder="Confirm Password"
                  name="password_confirmation"
                  placeholder="Confirm Password"
                  type="password"/>
                <error-messages :errors="errors.password_confirmation"/>


                <vs-button @click="savePassword">Salvează</vs-button>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {ErrorMessages},
  data () {
    return {
      passwordResetData: {
        password_confirmation: '',
        password: ''
      },
      errors: []
    }
  },
  computed: {},
  methods: {
    savePassword () {

      this.$http.post(process.env.VUE_APP_BASE_URL_API + this.$route.fullPath, this.passwordResetData)
        .then(() => {
          this.$router.push({name: 'home'})
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
          } else {
            // eslint-disable-next-line
            let notificationText
            if (response.status === 403) {
              notificationText = response.data.message
            } else {
              notificationText = 'A aparut o eroare la resetare. Va rugam reincarcati pagina sau incercati mai tarziu'
            }
            this.$store.dispatch('notify', {
              title: 'Eroare',
              text: notificationText,
              color: 'danger'
            })
          }
        })
    }

  }
}
</script>

<style lang="scss">
</style>
